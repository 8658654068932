* {
	box-sizing:border-box;
}

html {
	height:100%;
	min-height: 100%;
}


h1 {
    font-size: 30px;

}

h2 {
    font-size: 20px;
}

body {
	min-height: 100%;
    background-color: $border;
	padding-top:147px;
	background:silver;
	font-family:$quicksand;
}
