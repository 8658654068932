@font-face {
    font-family: 'quicksand';
    src: url('../fonts/quicksand/quicksand-bold-webfont.eot');
    src: url('../fonts/quicksand/quicksand-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-bold-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-bold-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-bold-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-bold-webfont.svg#quicksandbold') format('svg');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'quicksand';
    src: url('../fonts/quicksand/quicksand-regular-webfont.eot');
    src: url('../fonts/quicksand/quicksand-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-regular-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-regular-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-regular-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-regular-webfont.svg#quicksandregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

$quicksand: quicksand, sans-serif;