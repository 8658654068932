#topics {
    background-color: white;
    align-content: center;
    text-align: center;
}

#topics h1 {
    font-family: quicksand;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    color: $blue;
    text-align: center;
    padding-top: 7%;

}


#topics .intro-topics {
    font-family: $quicksand;
    width: 46%;
    align-content: center;
    text-align: center;
    color: $darkgray;
    font-size: 14px;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;

    @include max-width(1138px){
        width: 65%;
        align-content: center;

	}

}

#topics ul{
    padding-bottom: 7%;
    @media(max-width: 600px){
        margin-left: 10vw;
        margin-right: 10vw;
    }
}


#topics .list-topics {
    font-family: $quicksand;
    color: $darkgray;
    font-size: 12px;
    display: inline;
}

#topics h2 {
    width: 100%;
    font-family: quicksand;
    font-weight: bold;
    text-transform: uppercase;
    color: $orange;
    margin-bottom: 20px;
    font-size: 16px;
}




#topics li {
    width: 15vw;
    align-content: top;
    vertical-align: top;
    display: inline-block;
    list-style-type: none;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    padding-top: 25px;

    @include max-width(1269px){
		margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 35px;
        width: 15vw;
	}

    @include max-width(1138px){
		margin-left: 12px;
        margin-right: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 15vw;
	}

    @media(max-width:600px){
        display: block;
        width: 100%;
        margin-left: 0;
    }


    img {
        margin-bottom: 25px;
        align-content: center;
        width: 90%;
    }
}
