#future {
    background:url(../images/photos/landscape.jpg) center center no-repeat;
    background-size:cover;
    height:70vh;
}

#textbox1 {
    border-left:4px solid $orange;
    text-align:left;
    position: absolute;
    padding-left: 20px;
    max-width:370px;
    height: auto;
    margin-top: 70px;
        h1{
            color: $links;
            font-family: quicksand;
            font-size: 23px;
            text-transform: uppercase;
            font-weight: bold;
        }
        p {
            color: $links;
            font-size: 15px;
            line-height: 1.2;
            padding-top: 25px;
            font-family: $quicksand;
    }
}