.nav {
    width: 100%;
	top:0;
	left:0;
    overflow: hidden;
    background-color: $orange;
    position: fixed;
    z-index: 1000;
	box-shadow:none;

	&.small {
		box-shadow:0 2px 5px rgba(0,0,0,0.2);
		img {
			width:170px;
			margin-top:22px;
		}
		li a {
			padding:20px 0;
		}
	}

	nav {
		@extend %clearfix;
		text-align: center;
		@include max-width(900px){
			height:52px;
			img {
				width:170px;
				margin-top:22px;
			}
		}

	}

	h1 {
		position: absolute;
		left:50%;
		top:50%;
		transform:translate(-50%, -50%);
	}

	img {
		width: 250px;
		transition: all .3s;
		@include max-width(1100px){
			width:170px;
		}
	}

	ul {
		text-align: center;
		width:calc(50% - 125px);
		li {
			display: inline-block;
			& + li {
				margin-left:80px;
				@include max-width(1350px){
					margin-left:30px;
				}
			}
			&.spacer {
				width:250px;
			}
		}
		&.left {
			float:left;
		}
		&.right {
			float:right;
		}
		@include max-width(900px){
			display:none;

			.spacer {
				display: none;
			}
		}
	}

	li a {
		display: block;
		color: $links;
		padding: 95px 0 22px;
		text-decoration: none;
		font-family: $quicksand;
		text-transform: uppercase;
		font-size: 14px;
		transition: all .3s;
		@include max-width(1100px){
			padding-top:75px;
			font-size:12px;
		}
    }
}
    .teacher_resources {

        width: 100%;
        height: auto;
        background-color: $yellow;
    }


.teacher_resources_link {
        display: block;
		color: $links;
		padding-top: 30px;
        padding-bottom: 30px;
		text-decoration: none;
		font-family: quicksand;
		text-transform: uppercase;
		font-size: 14px;
        text-align: center;
}



#hero {
    background:url(../images/photos/Tetonboys.jpg) center center no-repeat;
    background-size:cover;
    height:80vh;
	color:white;
}

#bicentennial {

    .bicentennial_section {
        background-color: white;
        padding-bottom: 5%;
        padding-left: 60px;
        padding-right: 60px;
       @extend %clearfix;
    }
	.bicentennial-content {
		width:40%;
		float: left;
        padding-right: 40px;
        padding-top: 50px;
		@include max-width(950px){
			width:100%;
			float:none;
			padding-right:0;
		}
	}
        h1 {
            width: 100%;
            background-color: $blue;
			padding:20px 10px;
			line-height: 1.3;
            color: white;
            font-family: quicksand;
            font-size: 23px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
    }
        p {
            font-family: $quicksand;
            color: $darkgray;
            font-size: 18px;
            line-height: 1.7;
    }
	figure {
		margin-top: 2em;
		text-align: center;
		figcaption {
			text-transform: uppercase;
			margin-bottom: .5em;
		}
		img {
			width:200px;
		}
	}
        .photo {
            padding-top: 50px;
            float: right;
            width: 60%;
            margin-top: 0px;
			@include max-width(950px){
				width:100%;
				float:none;
				padding-right:0;
			}
        }

}





#intro {
    background:white;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    align-items: center;
        h1{
            color: $orange;
            font-family: quicksand;
            font-size: 23px;
            line-height: 1.2;
            font-weight: bold;
            text-transform: uppercase;

    }
        p {
            padding-top: 25px;
            font-family: $quicksand;
            color: $darkgray;
            font-size: 14px;
            line-height: 20px;
            padding-top: 2%;
    }
}

    .introtext {
    width: 50%;
    padding-right: 35px;
}

.videowrapper {
    width: 50%;
}

.videosize {
    width:100%;
  height:0;
  padding-top:56.25%;
  position: relative;
  margin-bottom:20px;
}

iframe {
  height:100%;
  width:100%;
  top:0;
  left:0;
  position: absolute;
}


.wrap {
    width: 100%;
    max-width:1400px;
    margin: 0 auto;
}

#hero .wrap {
    position: relative;
    height:100%;
}

#textbox {
    position: absolute;
    bottom:90px;
    right:0;
    border-right:4px solid $orange;
    text-align:right;
    padding-right:20px;
    max-width:500px;
        h1{
            font-family: quicksand;
            font-weight: bold;
            font-size: 36px;
            text-transform: uppercase;
        }
        p {
            font-size: 18px;
            line-height: 1.2;
            padding-top: 25px;
            font-family: $quicksand;
    }
}

