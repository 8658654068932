section#partners {
    background-color: lighten($blue, 67%);
    align-content: center;
    text-align: center;

}

#partners h1 {
    font-family: quicksand;
    text-transform: uppercase;
    font-weight: bold;
    color: $blue;
    text-align: center;
    font-size: 24px;
    padding-top: 7%;

}

#partners ul {
    padding-top: 5%;
    padding-bottom: 2.5%;
	text-align: center;

	li {
		width: 17vw;
		display: inline-block;
		padding-bottom: 30px;
		vertical-align: middle;
        max-width:250px;
	}
}


#partners h3 {
    font-family: quicksand;
    font-weight: bold;
    text-transform: uppercase;
    color: $orange;
    font-size: 16px;
    align-content: top;
    height: 35px;
    @media(max-width: 400px){
        font-size: 8px;
    }
}

#partners p {
    font-family: $quicksand;
    color: $darkgray;
    padding-top: 10%;
    font-size: 12px;
}

#partners img {
    margin-bottom: 30px;
    align-content: top;
    width: 85%;
    height: auto;
    border-radius:6px;
}

#partners button {
    border:none;
    background: transparent;
}

#partners .founding {
    text-align: center;
    a {
        padding:20px;
        background: white;
        margin:2.5% 0 3em;
        display: inline-block;
        max-width:500px;
        border-radius:12px;
        overflow: hidden;
        width:100%;
    }
    img {
        display: block;
        margin:0;
        width:100%;
    }
}