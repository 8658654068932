footer {
	background-color: $orange;
	height: 65px;
	position: relative;
	small {
		font-family: $quicksand;
		font-size: 13px;
		text-align: center;
		line-height: 65px;
		display: block;
		color:white;
		a {
			color:white;
		}
	}
	#bsu-logo {
		position: absolute;
		bottom: 15%;
		right: 20px;
		height: 70%;
		img {
			display: block;
			height:100%;
			width:auto;
		}
	}
}

.footer-downloads {
	padding:20px 0 10px 0;
	background: black;
	ul {
		display: flex;
		justify-content: center;
		@media (max-width:500px){
			display: block;
			text-align: center;
		}
	}
	li {
		margin:0 10px;
		width:150px;
		@media (max-width:500px){
			margin:10px 0;
		}
	}
	img {
		width:100%;
	}
}