@import "utilities/reset";

@import "config/colors";
@import "config/fonts";
@import "config/variables";

@import "utilities/extends";
@import "utilities/mixins";

@import "global";
@import "timeline";
@import "main-menu";
@import "locations-topics";
@import "back";
@import "partners";
@import "bottom-image";
@import "approach";
@import "footer";
@import "front-page";
