#timeline {
	background:white;
	color:$darkgray;
	min-height:800px;

	.wrap {
		position: relative;
		height:100%;
	}

	header {
		h1 {
			color:white;
			background:$orange;
			text-align: center;
			padding:30px 0;
			text-transform: uppercase;
		}
	}

	menu {
		background:$yellow;
		border-bottom:4px solid $orange;
		font-size: .9em;

		ul {
			text-align: center;

		}

		li {
			color:white;
			display: inline-block;
			position: relative;
			padding:1.4vw;
			cursor: pointer;

			&.current {

				&:before {
					position: absolute;
					content:"";
					width:2px;
					height:5px;
					background:$orange;
					left:50%;
					transform:translateX(-50%);
					bottom:0;
				}

				&:after {
					position: absolute;
					content:"";
					width:10px;
					height:10px;
					background:$orange;
					border-radius:50%;
					left:50%;
					transform:translateX(-50%);
					bottom:5px;
				}
			}
		}

	}

	#timeline-active-year {
		padding:50px;
		@extend %clearfix;
		height:100%;
		position: relative;
	}

	#timeline-active-year-count {
      width: 20%;
      float: left;
	  margin-left:10%;
      border-radius: 50%;
      background-color: $orange;
      position: relative;
      border: 15px solid white;
      box-sizing: border-box;

		@include max-width(700px){
			display:none;
		}

      #timeline-active-year-text{
        position: absolute;
        text-align: center;
        font-size: 54px;
        transform: translateY(-50%) translateX(-50%);
        top: 50%;
        left: 50%;
        color: white;
        font-weight: bold;
		  @include max-width(1000px){
			  font-size:3.4vw;
		  }
      }
    }

    #timeline-active-year-count:before{
      padding-top:100%;
      content:"";
      display:block;
    }

    #timeline-active-year-count:after{
      background-color:black;
      content:"";
    }

	#timeline-active-year-trips {
		width:54%;
		float:right;
		margin-right:10%;
		height:80%;
		position: relative;
		@include max-width(700px){
			float:none;
			margin:0 auto;
			width: 100%;
		}
	}

	#timeline-active-year-trip-list {
		height:calc(100% - 150px);
		position: absolute;
		top:0;
		left:0;
		opacity:1;
		transition: opacity .5s;
      li{
        font-size: 23px;
        font-weight: bold;
        color: $orange;
        cursor: pointer;
        margin-top: 25px;
        margin-bottom: 25px;
		  text-align: left;
      }
		h2 {
			font-style: italic;
			color:$darkgray;
		}
	}

	#timeline-active-year-active-trip {
		background:white;
		opacity:0;
		height:calc(100% - 150px);
		position: absolute;
		top:0;
		left:-40px;
		padding-left: 40px;
		overflow: auto;
		-webkit-overflow-scrolling:touch;
		transition:opacity .5s;
		position: relative;
		text-align: left;
		z-index: 1;
		@media(max-width: 400px){
			left: 0;
		}

		&.active {
			opacity:1;
			& ~ #timeline-active-year-trip-list {
				opacity:0;
			}
		}

		#timeline-active-year-back-button {
			color:$orange;
			font-weight:bold;
			background:none;
			border:0;
			position: absolute;
			top:0;
			left:0;
			font-size:31px;
			padding:0;
			cursor: pointer;
			outline:none;
		}

		h1 {
			margin-bottom: .5em;
		}
		h2 {
			font-weight: bold;
			margin-bottom: 1em;
		}

		ul {
			text-align: left;
			margin-bottom: 1em;
			li {
				display: block;
				margin-bottom: .5em;
			}
		}

		h3 {
			font-size: 16px;
		}

		p {
			line-height: 1.7;
			font-size: 14px;
			margin-bottom: 1.5em;
		}
		a {
			color:$orange;
		}
	}

}
@keyframes liveslide {
	0%{
		bottom:-250px;
		right:-250px;
	}
	100%{
		bottom:0;
		right:0;
	}
}

#live {
	position:fixed;
	bottom:-250px;
	right:-250px;;
	width:250px;
	z-index:1000;
	animation:1s liveslide 2s forwards;
}

#live img {
	width:100%;
	display:block;
}

#live-overlay {
	z-index:-1;
	opacity:0;
	transition:all .3s;
	position:fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
	background:rgba(0,0,0,0.3);
}

#live:hover + #live-overlay {
	opacity:1;
	z-index:999;
}
