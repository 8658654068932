$counterWidth: 130px;

#approach {
	background-color: lighten($blue, 67%);
	padding: 120px 60px;
	@media(max-width: 500px) {
		padding: 120px 0;
	}

	ol {
		margin: 60px 6vw 0;
		@extend %clearfix;
		@media(max-width: 500px) {
			margin: 0;
		}

		li {
			position: relative;
			width: 100%;
			clear: both;
			margin-top: 80px;
			@extend %clearfix;
			@include max-width(880px) {
				width: 90% !important;
				margin: 10px auto !important;
				float: none !important;
				left: auto !important;
				right: auto !important;
				height: auto !important;
				overflow: hidden; //clearfix

				.photo {
					display: none;
				}
			}

			img {
				width: calc(100% / 2 - 90px);
				height: auto;
				@media(max-width: 500px) {
					width: 100%;
				}
			}

			div {
				width: calc(100% / 2 - 90px);
				@media(max-width: 500px) {
					width: 100%;
				}
				@extend %clearfix;
				float: left;
			}

			p {
				// width: 30%;
			}

			span {
				width: 100px;
				height: 100px;
				background: #faa81a;
				display: block;
				border-radius: 100px;
				position: relative;
				text-align: center;
				line-height: 100px;
				font-size: 60px;
				font-weight: 900;
				margin: 20px 40px 0;
				@media(max-width: 500px) {
					margin-top: 60px;
					margin-bottom: 30px;
					margin-left: calc(100% - 220px);
				}

				&:before {
					width: 110px;
					height: 110px;
					border-radius: 110px;
					border: solid 7px black;
					content: "";
					position: absolute;
					top: -12px;
					left: -12px;
				}
			}
			// &:nth-child(odd){
			// 	left: -($counterWidth/2);
			// 	float:right;
			// 	.counter {
			// 		margin-right:20px;
			// 	}
			// }
			// &:nth-child(even){
			// 	right: -($counterWidth/2);
			// 	.counter {
			// 		float:right;
			// 		margin-left:20px;
			// 	}
			// 	.photo {
			// 		right:auto;
			// 		left:105%;
			// 	}
			// }
			.counter {
				float: left;
				width: $counterWidth;
				height: auto;
			}

			.photo {
				float: left;
				clear: both;
				position: absolute;
				right: 105%;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.resources {
			// height: 130px;
			// float: right;
			p {
				text-align: left;
			}
		}

		.website {
			text-align: right;
			height: 100%;
			@media(max-width: 500px) {
				text-align: left;
			}
			@extend %clearfix;

			p {
				text-align: right;
				@media(max-width: 500px) {
					text-align: left;
				}
			}
		}

		.webisodes {
			height: 100%;
			@extend %clearfix;

			p {
				text-align: left;
			}
		}

		.broadcast {
			text-align: right;
			height: 100%;
			@media(max-width: 500px) {
				text-align: left;
			}
			@extend %clearfix;

			p {
				text-align: right;
				@media(max-width: 500px) {
					text-align: left;
				}
			}
		}
	}

	h1 {
		font-family: quicksand;
		text-transform: uppercase;
		font-weight: bold;
		color: $blue;
		text-align: center;
		font-size: 24px;
	}

	h2 {
		font-family: quicksand;
		font-weight: bold;
		text-transform: uppercase;
		color: $orange;
		font-size: 16px;
		padding-top: 13px;
	}

	.container {
		width: 50%;
		padding-top: 2%;
		margin-left: 25%;
		margin-right: 25%;
	}

	.approach_description {
		font-family: $quicksand;
		color: $darkgray;
		font-size: 14px;
		line-height: 20px;
		padding-top: 3%;
		text-align: center;
	}

	p {
		font-family: $quicksand;
		color: $darkgray;
		padding: 20px 0;
		font-size: 16px;
		line-height: 1.3;
		overflow: hidden;
	}
}

.even {
	float: right;
	@media(max-width: 500px) {
		float: none;
	}
}

.odd {
	float: left;
	@media(max-width: 500px) {
		float: none;
	}
}
