#pioneers {
	background:white url(../images/landscape.svg) center center no-repeat;
	background-size:cover;
	overflow: hidden;
}

#pioneers .wrap {
	padding:80px 10px 200px 7vw;
	min-height:700px;
	position:relative;
	@media(max-width: 700px){
		background: rgba(255, 255, 255, 0.8);
	}
	@media (max-width:700px){
		padding-bottom: 375px;
	}
	@media (max-width:550px){
		padding-bottom: 500px;
	}
}

#pioneers .info {
	max-width: 450px;
}

#pioneers .info h1 {
background:#045BA6;
padding:10px 20px;
margin-bottom: 40px;
position: relative;
}

#pioneers .info h1:after {
content:"";
position: absolute;
top:0;
right:100%;
width:1000px;
height:100%;
background:#045BA6;
}

#pioneers .info h1 img {
	width:100%;
}
#pioneers .info h2 {
	color:#045BA6;
	text-transform: uppercase;
	margin-bottom: .5em;
	font-size: 32px;
	font-weight: bold;
}

#pioneers .info h3 {
	color:#045BA6;
	margin-bottom: .5em;
	font-size: 18px;
	font-weight: bold;
}

#pioneers .info p {
	color:#333333;
	line-height: 1.5;
	font-size: 16px;
	margin-bottom: 3em;
}

#pioneers .info a {
	color:white;
	padding:20px 20px;
	background:#FAA719;
	border-radius:20px;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: bold;
	font-size: 24px;
}



#pioneers .partners {
	position: absolute;
	bottom:20px;
	right:20px;
	color:white;
	@media (max-width:700px){
		filter:invert(1);
	}
}

#pioneers .partners a {
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
}

#pioneers .partners img {
	height:40px;
}

#overview .wrap {
	background:white;
	padding:80px 7vw 80px 7vw;
	position: relative;
}

#overview .wrap:after {
	content:"";
	display:table;
	clear:both;
}

#overview .left {
	width:50%;
	float: left;
	@media(max-width: 700px){
		width: 100%;
		margin-bottom: 30px;
	}
}

#overview .right {
	width:40%;
	float: right;
	@media(max-width: 700px){
		width: 100%;
		margin-bottom: 30px;
	}
}

#overview p {
	line-height: 1.7;
	font-size: 18px;
}

#overview .right img {
	width:100%;
	border-radius:10px;
}

#kids {
	position: absolute;
	bottom: -0.8vw;
	right:80px;
	width:35%;
	@media(max-width: 400px){
		width: 60%;
	}
}

.nav ul {
	width:calc(50% - 115px);
}

@media (max-width: 1350px){
	.nav ul li + li {
	    margin-left: 25px;
	}
}

.downloads {
	position: absolute;
	right:20px;
	bottom:80px;
	text-align: left;
	@media (max-width:1125px){
		text-align: right;
	}
	.web-app-link {
		text-decoration: none;
		color:white;
		border:4px solid $yellow;
		background: #045BA6;
		padding:20px 20px;
		border-radius:20px;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 18px;
		text-align: center;
		display: inline-block;
		line-height: .8;
		margin-bottom: 20px;
		position: relative;
		transition:transform .3s;
		&:hover, &:focus {
			transform:scale(1.15);
		}
		span {
			font-size: 24px;
			display: block;
		}
		img {
			position: absolute;
			top:-30px;
			left:-20px;
			width:120px;
			transform:rotate(-15deg);
		}
	}
	@media (max-width:550px){
		left:20px;
		text-align: center;
	}
	ul {
		display: flex;
		@media (max-width:550px){
			display: block;
			text-align: center;
		}
	}
	li {
		+ li {
			margin-left: 10px;
			@media (max-width:550px){
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}
	img {
		width:150px;
	}
}