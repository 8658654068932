%clearfix{
	&:after {
		content:"";
		display:table;
		clear:both;
	}
}

%noselect {
	user-select:none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

%select {
	user-select:text;
	-webkit-tap-highlight-color: rgba(0,0,0,.1);
}

%grayscale {
	filter:grayscale(100%);
}

%doublearrow {
	&:after {
		content:"\00BB";
		display: inline-block;
		margin-left: 4px;
	}
}

%fullabsolute {
	top:0;
	left:0;
	width:100%;
	height:100%;
}