#back {
	background:lighten($blue, 67%);
	text-align: center;
	padding:120px 20px;
	h1 {
		margin-bottom: .5em;
		font-size: 36px;
	}
	p {
		max-width:800px;
		font-size: 21px;
		margin:0 auto;
		line-height: 1.7;
		@include max-width(750px){
			max-width:none;
		}
	}
	h2 {
		margin: 0 0 .5em;
		color:$yellow;
		font-weight:bold;
		font-size: 36px;
	}
	.signup {
		margin-top:60px;
		p {
			font-size: 16px;
			margin-bottom:2em;
		}
		form {
			background:$orange;
			padding:40px;
			max-width:800px;
			width:100%;
			margin:0 auto;
			color:white;
			text-align: right;
			@extend %clearfix;
		}
		.info {
			float: left;
			width:60%;
			text-align: left;
		}
		.form {
			float: right;
			width:35%;
			margin-top:20px;
		}
		label {
			font-size:12px;
			margin-bottom:.5em;
			display: block;
			cursor: pointer;
			text-align: left;
		}
		input {
			display:block;
			width:100%;
			font-size:18px;
			padding:6px;
			margin-bottom:10px;
			font-family: $quicksand;
			border:none;
			text-align: center;
		}
		input[type=submit] {
			display: inline-block;
			font-size:18px;
			background:$blue;
			color:white;
			text-transform: uppercase;
			width:auto;
			padding: 10px 30px;
			margin-bottom: 0;
			margin-top:20px;
			cursor: pointer;
		}
		@include max-width(600px){
			.info {
				float:none;
				width:100%;

			}
			.form {
				float:none;
				width:100%;
			}
		}
	}
}